import React from 'react';
import { BroadcastCategory, ChatMessage, Users } from 'src/javascripts/shared/chat';
import { formatDate } from '../../services/messageHelpers';

interface Props {
  category: BroadcastCategory;
  currentCategoryId: string;
  setCurrentCategoryId: (id: string) => void;
  teaser?: ChatMessage;
  unreadMessages?: number;
  users: Users;
}

export default function BroadcastCategoryItem({ category, currentCategoryId, setCurrentCategoryId, teaser, unreadMessages, users }: Props) {
  const activeClass = currentCategoryId === category.id ? 'selected' : '';

  return (
    <div key={category.id} className={['item', activeClass].join(' ')} onClick={() => setCurrentCategoryId(category.id)}>
      <div className="avatar"><img src={category.icon}/></div>
      <div className="name">{category.name}</div>
      <div className="teaser">{teaser && `${users[teaser.sender.chatUserId].name}: ${teaser.text}`}</div>
      <div className="time">{teaser && formatDate(teaser.createdAt)}</div>
      {unreadMessages > 0 && <div className="notifications">{unreadMessages}</div>}
    </div>
  );
}
