import PinchZoom from 'pinch-zoom-js';
import React from 'react';
import AppendToBody from 'src/javascripts/components/shared/AppendToBody';

interface Props {
  onLoad: () => void;
  src: string;
}

interface State {
  open: boolean;
}

export default class ImageWithLightbox extends React.Component<Props, State> {
  state = { open: false };

  componentDidUpdate() {
    if (this.state.open) {
      document.addEventListener('keydown', this.closeOnEsc);
      const _pinchZoom = new PinchZoom(document.querySelector('#lightbox-img'));
    } else {
      document.removeEventListener('keydown', this.closeOnEsc);
    }
  }

  fixOnLoad = img => {
    if (!img) {
      return;
    }

    img.onload = this.props.onLoad;

    if (img.complete) {
      img.onload();
    }
  }

  closeOnEsc = ({ keyCode }: { keyCode: number }) => {
    if (keyCode === 27) {
      this.setState({ open: false });
    }
  }

  render() {
    const { open } = this.state;
    const { src } = this.props;

    return (
      <>
        <img src={src} ref={this.fixOnLoad} onClick={() => this.setState({ open: true })}/>
        {open && (
          <AppendToBody>
            <div className="lightbox" onClick={() => this.setState({ open: false })}>
              <img id="lightbox-img" src={src}/>
            </div>
          </AppendToBody>
        )}
      </>
    );
  }
}
