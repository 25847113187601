import React from 'react';
import { hoistStatics } from 'recompose';
import { IChatService } from './interfaces';

export const ChatContext = React.createContext<IChatService | undefined>(undefined);

const chatEnhancer = (ChildComponent: React.ComponentType) => (props: any) => {
  return (
    <ChatContext.Consumer>
      {chatService => <ChildComponent {...props} chatService={chatService}/>}
    </ChatContext.Consumer>
  );
};

// TODO: How to type this?
export const withChatContext = hoistStatics(chatEnhancer as any);
