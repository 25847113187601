import React from 'react';
import { defaultAvatarForUser, formatDate } from 'src/javascripts/services/messageHelpers';
import { ChatMessage, ChatMessageSender, Room } from 'src/javascripts/shared/chat';

interface Props {
  currentRoom: Room;
  room: Room;
  customer?: ChatMessageSender;
  setCurrentRoom: (room: Room) => void;
  teaser: ChatMessage;
  unreadMessages?: number;
}

export default function RoomItem({ room, currentRoom, customer, setCurrentRoom, teaser, unreadMessages }: Props) {
  if (!customer) {
    return null;
  }
  const activeClass = currentRoom === room ? 'selected' : '';
  const avatarUrl = defaultAvatarForUser(customer);
  const time = teaser && formatDate(teaser.createdAt) || room.lastMessageAt && formatDate(room.lastMessageAt);

  return (
    <div key={room.id} className={['item', activeClass].join(' ')} onClick={() => setCurrentRoom(room)}>
      <div className={`avatar ${customer.presence.state}`}><img src={avatarUrl}/></div>
      <div className="name">{customer.name}</div>
      <div className="teaser">{teaser && teaser.text}</div>
      <div className="time">{time}</div>
      {unreadMessages > 0 && <div className="notifications">{unreadMessages}</div>}
    </div>
  );
}
