export default class ApiService {
  static fetch(input: RequestInfo, init?: RequestInit, contentType: string | null = 'application/json') {
    const headers = {
      Authorization: window.CityMessenger.chatConfig.token,
    };
    if (contentType) {
      headers['Content-Type'] = contentType;
    }
    const method = 'get';

    return new Promise<Response>((resolve, reject) => {
      fetch(input, { headers, method, ...init }).then(response => {
        if (response.ok) {
          return resolve(response);
        } else {
          return reject(response);
        }
      });
    });
  }
}
