import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useMessagesContext } from 'src/javascripts/contexts/chatMessagesContext';
import t from 'src/javascripts/services/translations';
import { PusherUser, Room, transformChatMessageSender, Users } from 'src/javascripts/shared/chat';
import { isWrapperApp } from 'src/javascripts/shared/chat/layout-helpers';
import CustomerInvitation from './CustomerInvitation';
import RoomItem from './RoomItem';

interface Props extends RouteComponentProps {
  rooms: Room[];
  currentRoom: Room;
  currentUser?: PusherUser;
  users: Users;
}

export default function Rooms(props: Props) {
  const { rooms, currentRoom, currentUser, users, history } = props;
  if (!currentUser) {
    return null;
  }

  const { directChat } = useMessagesContext();

  return (
    <div className="rooms-container">
      <div className="list">
        {rooms.map(room => (
          <RoomItem
            key={room.id}
            room={room}
            currentRoom={currentRoom}
            customer={transformChatMessageSender(users[room.customer.chatUserId])}
            setCurrentRoom={r => history.push(`/direct/${r.id}`)}
            teaser={directChat(room.customer.id).teaser}
            unreadMessages={directChat(room.customer.id).unreadMessageIds.length}
          />
        ))}
      </div>
      {isWrapperApp() && (
        <>
          {!rooms.length && <div className="no-rooms position-relative">{t('direct.not_ready.no_messages')}</div>}
          <Link to="/customer_invitation" className="btn btn-secondary position-relative">
            {t('customer_invitation.phone_invite')}
          </Link>
        </>
      )}
      {!isWrapperApp() && <CustomerInvitation {...props} />}
    </div>
  );
}
