import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { defaultAvatarForUser } from 'src/javascripts/services/messageHelpers';
import { Room, Users } from 'src/javascripts/shared/chat';
import { isWrapperApp } from 'src/javascripts/shared/chat/layout-helpers';

interface Props extends RouteComponentProps<{roomId: string}> {
  users: Users;
  rooms: Room[];
}

export default function DirectChatHeader({ users, rooms, history: { replace: redirect }, match: { params: { roomId } } }: Props) {
  const room = rooms.find(r => r.id === roomId) || rooms[0];
  if (!isWrapperApp() || !room) {
    return null;
  }
  const customer = users[room.customer.chatUserId];
  const avatarUrl = defaultAvatarForUser(customer);
  return (
    <div className="chat-header">
      <a className="btn btn-link" onClick={() => redirect('/direct')} title="back">
        <i className="fas fa-arrow-left"/>
      </a>
      <div className={`avatar ${customer.presence.state}`}><img src={avatarUrl}/></div>
      <div>{customer.name}</div>
    </div>
  );
}
