import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import t from 'src/javascripts/services/translations';
import { isWrapperApp } from 'src/javascripts/shared/chat/layout-helpers';
import InvitationModal from './InvitationModal';

interface Props extends RouteComponentProps {
  showHeader?: boolean;
}

export default function CustomerInvitation({ showHeader, history: { replace: redirect } }: Props) {
  const [invitationModalOpen, setInvitationModalOpen] = useState(false);

  return (
    <div>
      {invitationModalOpen && <InvitationModal cancel={() => setInvitationModalOpen(false)}/>}
      {showHeader && (
        <div className="chat-header">
          <a className="btn btn-link" onClick={() => redirect('/')} title="back">
            <i className="fas fa-arrow-left"/>
          </a>
          {t('customer_invitation.title')}
        </div>
      )}
      <div className="customer-invitation">
        <p className="mb-1">
          {t('customer_invitation.phone_invite')}
        </p>
        <form action="/relationships/initiate" method="post">
          <input type="hidden" name="authenticity_token" value={window.CityMessenger.csrfToken} />
          {isWrapperApp() && <input type="hidden" name="wrapper_app" value="1" id="wrapper_app" />}
          <div className="row">
            <div className="col-11">
              <div className="input-group input-group-btn">
                <input
                  type="text"
                  id="mobile_numbers"
                  className="form-control"
                  name="mobile_numbers"
                  placeholder={t('customer_invitation.phone_placeholder')}
                />
                <input
                  type="submit"
                  name="commit"
                  value={t('customer_invitation.search')}
                  className="btn btn-primary ml-1"
                  data-disable-with={t('customer_invitation.search')}
                />
              </div>
            </div>
            <div className="col-1 pl-0 pr-0 d-flex align-items-center">
              <a onClick={() => setInvitationModalOpen(true)}><i className="fal fa-file-excel excel-upload"/></a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
