import { throttle } from 'lodash';
import React, { createRef, useEffect, useRef } from 'react';
import Message from 'src/javascripts/components/messages/Message';
import { ChatMessage, MultipartMessage, PlanInformation, PlannedBroadcastChatMessage, Users } from 'src/javascripts/shared/chat';

interface Props {
  messages: Array<ChatMessage | PlannedBroadcastChatMessage>;
  currentUserId: string;
  recipient: string;
  loadEarlierMessages: (page: number) => Promise<MultipartMessage[]>;
  actions?: Array<[string, (message: ChatMessage | PlannedBroadcastChatMessage) => any]>;
  deleteAction: (message: ChatMessage) => void;
  quotaMessage?: string;
  users: Users;
}

let page: number;
// to not try to fetch the same page multiple times
let refreshing: boolean;
// when i was at the bottom, it should scroll together with incoming messages, otherwise it keeps the scroll position
let isAtBottom: boolean;
// no further messages loaded, no need to perform the pagination
let isOnTop: boolean;
// to keep the track of the scrollbar, so that there's always a gap from the top where can scroll
let scrollFromBottom: number;

export default function Messages({ messages, currentUserId, actions = [], deleteAction, loadEarlierMessages,
                                   recipient, quotaMessage = '', users }: Props) {
  const PAGINATION_SCROLL_OFFSET = 500;
  const scroll = () => {
    /* istanbul ignore if */
    if (!chatRef.current) {
      return;
    }
    const scrollHeight = chatRef.current.scrollHeight;
    chatRef.current.scrollTop = isAtBottom ? scrollHeight : scrollHeight - scrollFromBottom;
  };
  const chatRef = useRef<HTMLDivElement>(null);
  useEffect(scroll, [messages]);
  useEffect(() => {
    page = 1;
    refreshing = false;
    isAtBottom = true;
    isOnTop = false;
    scrollFromBottom = 0;
    scroll();
  }, [recipient]);

  const handleScroll = async () => {
    /* istanbul ignore if */
    if (!chatRef.current) {
      return;
    }
    const { scrollHeight, scrollTop, clientHeight } = chatRef.current;
    isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= 1;
    scrollFromBottom = scrollHeight - scrollTop;
    if (chatRef.current.scrollTop > PAGINATION_SCROLL_OFFSET || !loadEarlierMessages || refreshing || isOnTop) {
      return;
    }
    refreshing = true;
    page += 1;
    const incomingMessages = await loadEarlierMessages(page);
    if (!incomingMessages.length) {
      isOnTop = true;
    }
    refreshing = false;
  };

  useEffect(() => {
    const scrollFn = throttle(handleScroll, 400);
    chatRef.current.addEventListener('scroll', scrollFn);
  }, [chatRef]);

  return (
    <div className="chat" ref={chatRef}>
      {(messages).map(message => (
        <Message
          key={message.id}
          currentUserId={currentUserId}
          message={message}
          imgLoaded={scroll}
          actions={actions}
          deleteAction={deleteAction}
          users={users}
        />
      ))}
      <div className="quota w-100 mt-3 text-center" dangerouslySetInnerHTML={{ __html: quotaMessage }}/>
    </div>
  );
}
