import { Picker } from 'emoji-mart';
import React, { KeyboardEvent, ReactNode, useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { BroadCastSubmitType } from 'src/javascripts/components/messages/BroadcastChat';
import BroadcastReferencesCouponSelection from 'src/javascripts/components/messages/BroadcastReferencesCouponSelection';
import { DraftedMessage } from 'src/javascripts/services/draftService';
import t from 'src/javascripts/services/translations';
import MessagingService from '../../services/messagingService';
import { toast } from '../../services/toast';
import { isWrapperApp } from '../../shared/chat/layout-helpers';

interface Props {
  submitMessage: () => any;
  draftMessage: (text: string, image?: string, cb?: () => any) => any;
  draftedMessage: DraftedMessage;
  isBlocked: boolean;
  children?: ReactNode;
  submitType?: BroadCastSubmitType;
  isQuotaReached?: boolean;
  isStarter?: boolean;
  isVoucherIssuer?: boolean;
  setReferencedCouponId?: (referencedCouponId: string) => any;
}

export default function ChatInput({
  submitMessage, isBlocked, draftMessage, draftedMessage: { text, image, referencedCouponId }, children,
  submitType = BroadCastSubmitType.send, isQuotaReached, isVoucherIssuer, setReferencedCouponId,
}: Props) {

  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [inputCursorPosition, setInputCursorPosition] = useState(0);

  useEffect(() => {
    document.addEventListener('keyup', closeEmojiPickerHandler, false);
    return () => {
      document.removeEventListener('keyup', closeEmojiPickerHandler, false);
    };
  }, []);

  const closeEmojiPickerHandler = (event) => {
    if (event.keyCode === 27) {
      event.preventDefault();
      setEmojiPickerVisible(false);
    }
  };

  const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      draftMessage(text, image, submitMessage);
    }
  };

  const updateCursorPosition = (event) => {
    setInputCursorPosition(event.nativeEvent.target.selectionStart);
  };

  const addEmoji = (emoji) => {
    if (isBlocked) {
      return;
    }
    const newText = text.substring(0, inputCursorPosition) + emoji.native + text.substring(inputCursorPosition);
    setInputCursorPosition(inputCursorPosition + 2);
    draftMessage(newText, image);
  };

  const submitImage = async (selectedImage: File) => {
    try {
      const response = await MessagingService.submitImage(selectedImage);
      draftMessage(text, response.url);
    } catch (_e) {
      toast('error', 'chat.image.submission_failed');
      draftMessage(text, image);
    }
  };

  const displayQuotaMessage = () => {
    return isQuotaReached && <div className="alert alert-danger rounded-0">{t('chat_input.quota_reached')}</div>;
  };

  return (
    <>
      <div className="input">
        {image && (
          <div className="preview-image">
            <img src={image}/>
            <i onClick={() => draftMessage(text, undefined)} className="fal fa-times"/>
          </div>
        )}
        <div>
          {emojiPickerVisible && (
            <div className="emoji-picker">
              <Picker onSelect={addEmoji} showPreview={false} showSkinTones={false} emojiSize={20} native={true} autoFocus={true} />
            </div>
          )}
        </div>
        <div className="field">
          <input
            id="imageUpload"
            disabled={isBlocked || isQuotaReached}
            type="file"
            name="imageAttachment"
            accept="image/png, image/jpeg, image/jpg, image/gif"
            onChange={event => submitImage(event.target.files[0])}
            hidden={true}
          />
          <label htmlFor="imageUpload" className="fal fa-file-image field-toggle"/>
          {!isWrapperApp() && <i className="fal fa-smile field-toggle" onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}/>}
          {isVoucherIssuer && (
            <BroadcastReferencesCouponSelection
              referencedCouponId={referencedCouponId}
              setReferencedCouponId={setReferencedCouponId}
            />
          )}
          <TextareaAutosize
            value={text}
            className="form-control"
            name="message"
            disabled={isBlocked || isQuotaReached}
            placeholder={t('chat_input.placeholder')}
            onKeyDown={onKeyDown}
            onChange={({ target: { value } }) => draftMessage(value, image)}
            onBlur={() => draftMessage(text, image)}
            onKeyUp={updateCursorPosition}
            onClick={updateCursorPosition}
            maxRows={5}
          />
          <i className={`${submitType} fal`} onClick={submitMessage}/>
        </div>
        {children}
        {isBlocked && <div className="alert alert-danger rounded-0">{t('chat_input.blocked')}</div>}
        {displayQuotaMessage()}
      </div>
    </>
  );
}
