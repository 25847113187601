import { sortBy, uniq, uniqWith } from 'lodash';
import React, { Component, useContext } from 'react';
import { selectTeaser, transformToChatMessages, uniqMessageComparator } from 'src/javascripts/services/messageHelpers';
import { ChatMessage, Conversations } from 'src/javascripts/shared/chat';

type SupportMessageTypes = 'direct' | 'broadcast';

interface State {
  direct: {
    [customerId: string]: {
      messages: ChatMessage[],
      unreadMessageIds: string[],
      teaser?: ChatMessage,
    },
  };
  broadcast: {
    [categoryId: string]: {
      messages: ChatMessage[],
      unreadMessageIds: string[],
      teaser?: ChatMessage,
    },
  };
}

export interface ChatMessagesContextValue {
  directChat: (customerId: string) => State['direct']['customerId'];
  broadcastChat: (categoryId: string) => State['broadcast']['categoryId'];
  addDirectConversations: (conversations: Conversations) => void;
  addBroadcastConversations: (conversations: Conversations) => void;
  markDirectChatAsRead: (customerId: string) => void;
  markBroadcastChatAsRead: (categoryId: string) => void;
  removeDirectMessage: (customerId: string, messageId: string) => void;
  removeBroadcastMessage: (categoryId: string, messageId: string) => void;
}

export const ChatMessagesContext = React.createContext<ChatMessagesContextValue>(null);

export const useMessagesContext = () => useContext(ChatMessagesContext);

export default class ChatMessagesProvider extends Component<JSX.ElementChildrenAttribute, State> {
  state: State = {
    direct: {},
    broadcast: {},
  };

  get value(): ChatMessagesContextValue {
    return {
      directChat: this.access('direct'),
      broadcastChat: this.access('broadcast'),
      addDirectConversations: this.addConversations('direct'),
      addBroadcastConversations: this.addConversations('broadcast'),
      markDirectChatAsRead: this.markChatAsRead('direct'),
      markBroadcastChatAsRead: this.markChatAsRead('broadcast'),
      removeDirectMessage: this.removeChatMessage('direct'),
      removeBroadcastMessage: this.removeChatMessage('broadcast'),
    };
  }

  render() {
    return <ChatMessagesContext.Provider value={this.value}>{this.props.children}</ChatMessagesContext.Provider>;
  }

  private addConversations = (key: SupportMessageTypes) => (conversations: Conversations) => {
    const chat = { ...this.state[key] };
    Object.entries(conversations).forEach(([id, conversation]) => {
      const { messages: oldMessages, unreadMessageIds: oldUnreadMessageIds } = this.access(key)(id);
      const newMessages = transformToChatMessages<ChatMessage>(conversation.messages);
      const messages = sortBy(uniqWith<ChatMessage>([...newMessages, ...oldMessages], uniqMessageComparator), ['createdAt']);
      const unreadMessageIds = uniq([...conversation.unreadMessageIds, ...oldUnreadMessageIds]);

      chat[id] = { messages, unreadMessageIds, teaser: selectTeaser(messages) };
    });

    this.setState({ ...this.state, [key]: chat });
  }

  private markChatAsRead = (key: SupportMessageTypes) => (id: string) => {
    const { messages, teaser } = this.access(key)(id);
    this.setState({
      ...this.state, [key]: {
        ...this.state[key],
        [id]: { messages, unreadMessageIds: [], teaser },
      },
    });
  }

  private access = (key: SupportMessageTypes) => (id: string) => {
    return this.state[key][id] || { messages: [], unreadMessageIds: [] };
  }

  private removeChatMessage = (key: SupportMessageTypes) => (id: string, messageId: string) => {
    const { messages: oldMessages, unreadMessageIds: oldUnreadMessageIds } = this.access(key)(id);
    const messages = oldMessages.filter(({ id }) => id !== messageId);
    const unreadMessageIds = oldUnreadMessageIds.filter((id) => id !== messageId);

    this.setState({
      ...this.state, [key]: {
        ...this.state[key],
        [id]: { messages, unreadMessageIds, teaser: selectTeaser(messages) },
      },
    });
  }
}
