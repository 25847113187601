import { deserialize } from '../services/serialization';
import { BaseChatService } from './base-chat-service';
import { BroadcastMultipartMessage, Channel, ChatUser, IChatService, MultipartMessage, PusherUser, PusherUserInfo, Room, Users } from './interfaces';

export const PAGE_SIZE = 20;
export const PRESENCE_CHANNEL = 'presence-citymessenger';
export const BROADCASTS_CHANNEL = 'private-broadcasts';
export const PEER_TO_PEER_CHANNEL = 'private-peer-to-peer';
export const DIRECT_CHANNEL_PREFIX = 'private-direct-';
export enum EVENTS {
  newMessage = 'new_message',
  messageDeleted = 'message_deleted',
  roomBlocked = 'room_blocked',
  roomUnblocked = 'room_unblocked',
  customerUpdate = 'customer_update',
  shopUpdate = 'shop_update',
  favouriteAdded = 'favourite_added',
  favouriteRemoved = 'favourite_removed',
  channelUnarchived = 'channel_unarchived',
}

interface AdditionalHeaders {
  [key: string]: string;
}

export class PusherChatService extends BaseChatService implements IChatService {
  pusher: Pusher.Pusher;
  user?: PusherUser;
  usersCache: Users = {};
  roomsCache: Room[] = [];
  presence: { [id: string]: boolean } = {};
  isConnecting: boolean = false;
  presenceChannel?: Pusher.Channel;
  connectionRejection?: (reason: string) => void;

  constructor(
    pusherKlass: any,
    private apiNamespace: string,
    tokenProviderUrl: string,
    channelsInstanceId: string,
    private token: string,
    private additionalHeaders: AdditionalHeaders = {},
  ) {
    super();
    this.pusher = new pusherKlass(channelsInstanceId, {
      cluster: 'eu',
      forceTLS: true,
      authEndpoint: tokenProviderUrl,
      auth: {
        headers: {
          Authorization: token,
          ...additionalHeaders,
        },
      },
    });
  }

  connect = () => {
    if (this.isConnecting) {
      return Promise.reject('connecting');
    }
    this.isConnecting = true;
    this.pusher.connect();
    this.presenceChannel = this.pusher.subscribe(PRESENCE_CHANNEL);
    return new Promise<PusherUser>((resolve, reject) => {
      this.connectionRejection = (reason: string) => reject(reason);
      const timeoutHandle = setTimeout(() => this.connectionRejection!('timeout'), 20000);
      this.presenceChannel!.bind('pusher:subscription_succeeded', async (data: { me: PusherUser, members: PusherUserInfo[] }) => {
        this.user = data.me;
        await this.subscribeToChannels();
        await Promise.all([this.fetchRooms(), this.fetchUsers()]);
        Object.values(data.members).forEach((memberInfo: PusherUserInfo) => this.presence[memberInfo.chatUserId] = true);
        this.isConnecting = false;
        this.connectedSubject.next(this.user);
        clearTimeout(timeoutHandle);
        resolve(this.user);
      });

      this.presenceChannel!.bind('pusher:subscription_error', (data: unknown) => {
        this.user = undefined;
        this.connectedSubject.next(this.user);
        this.isConnecting = false;
        clearTimeout(timeoutHandle);
        reject(data);
      });

      this.presenceChannel!.bind('pusher:member_added', (member: Pusher.Member<PusherUserInfo>) => {
        this.presence[member.info.chatUserId] = true;
      });
      this.presenceChannel!.bind('pusher:member_removed', (member: Pusher.Member<PusherUserInfo>) => {
        delete this.presence[member.info.chatUserId];
      });
    });
  }

  disconnect = () => {
    if (this.presenceChannel) {
      this.presenceChannel.unbind();
    }
    this.pusher.unsubscribe(PRESENCE_CHANNEL);
    this.pusher.unsubscribe(BROADCASTS_CHANNEL);
    if (this.connectionRejection) {
      this.connectionRejection('disconnected');
    }
    if (this.user) {
      this.pusher.unsubscribe(this.directChannelName());
    }
    this.isConnecting = false;
    this.user = undefined;
    this.pusher.disconnect();
    return Promise.resolve();
  }

  subscribeToChannels = async () => {
    // TODO: Handle connection errors
    const directChannel = this.pusher.subscribe(this.directChannelName());
    directChannel.bind(EVENTS.roomBlocked, this.toggleRoom('blocked', true));
    directChannel.bind(EVENTS.roomUnblocked, this.toggleRoom('blocked', false));
    directChannel.bind(EVENTS.favouriteAdded, this.toggleRoom('favourite', true));
    directChannel.bind(EVENTS.favouriteRemoved, this.toggleRoom('favourite', false));
    directChannel.bind(EVENTS.newMessage, (message: MultipartMessage) => {
      this.sentDirectMessagesSubject.next(message);
      if (!this.usersCache[message.sender.chatUserId]) {
        this.fetchRooms();
        this.fetchUsers();
      }
    });
    directChannel.bind(EVENTS.messageDeleted, (message: MultipartMessage) => {
      this.removedDirectMessagesSubject.next(message);
    });
    directChannel.bind(EVENTS.channelUnarchived, (channel: Channel) => this.unarchivedChannelsSubject.next(channel));

    const peerToPeerChannel = this.pusher.subscribe(PEER_TO_PEER_CHANNEL);
    peerToPeerChannel.bind(EVENTS.newMessage, (message: MultipartMessage) => {
      directChannel.emit(EVENTS.newMessage, message);
    });
    peerToPeerChannel.bind(EVENTS.messageDeleted, (message: MultipartMessage) => {
      directChannel.emit(EVENTS.messageDeleted, message);
    });

    const broadcastChannel = this.pusher.subscribe(BROADCASTS_CHANNEL);
    broadcastChannel.bind(EVENTS.newMessage, (message: BroadcastMultipartMessage) => this.sentBroadcastMessagesSubject.next(message));
    broadcastChannel.bind(EVENTS.customerUpdate, this.updateUser('customer'));
    broadcastChannel.bind(EVENTS.shopUpdate, this.updateUser('shop'));
    broadcastChannel.bind(EVENTS.messageDeleted, (message: BroadcastMultipartMessage) => {
      this.removedBroadcastMessagesSubject.next(message);
    });
  }

  private updateUser = (userType: string) => {
    return async ({ id }: { id: string }) => {
      const chatUserId = `${userType}-${id}`;
      const outdatedUser = this.usersCache[chatUserId];

      if (outdatedUser) {
        const updatedUser: ChatUser = await this.fetchData(`chat_users/find?${userType}_id=${id}`);
        this.usersCache[updatedUser.chatUserId] = { ...outdatedUser, ...updatedUser };
        this.usersSubject.next(this.usersCache);
      }
    };
  }

  private toggleRoom = (attribute: 'favourite' | 'blocked', value: boolean) => {
    return (data: { customer: string; }) => {
      const room = this.roomsCache.find(r => r.customer.id === data.customer);
      if (room) {
        room[attribute] = value;
        this.roomsSubject.next(this.roomsCache);
      }
    };
  }

  private directChannelName = () => {
    return `${DIRECT_CHANNEL_PREFIX}${this.user!.info.chatUserId}`;
  }

  private fetchRooms = async () => {
    this.roomsCache = await this.fetchData('rooms');
    this.roomsSubject.next(this.roomsCache);
    return this.roomsCache;
  }

  private fetchUsers = async () => {
    const users = await this.fetchData('chat_users');
    this.cacheUsers(users);
    return users;
  }

  private extendWithPresence = (user: ChatUser) => {
    user.presence = { state: 'offline' };
    Object.defineProperty(user.presence, 'state', {
      get: () => this.presence[user.chatUserId] && 'online' || 'offline',
      configurable: true,
    });
    return user;
  }

  private cacheUsers = (users: ChatUser[]) => {
    users.forEach(user => this.usersCache[user.chatUserId] = this.extendWithPresence(user));
    this.usersSubject.next(this.usersCache);
  }

  private fetchData = async (endpoint: string) => {
    const data = await fetch(`${this.apiNamespace}/${endpoint}`, {
      method: 'get',
      headers: { 'Authorization': this.token, 'Content-Type': 'application/json', ...this.additionalHeaders },
    }).then(response => response.json());
    return deserialize(data);
  }
}
