import React from 'react';
import AppendToBody from 'src/javascripts/components/shared/AppendToBody';
import ApiService from 'src/javascripts/services/apiService';
import t from 'src/javascripts/services/translations';
import { Coupon } from 'src/javascripts/shared/chat';
import { deserialize } from 'src/javascripts/shared/services/serialization';

interface Props {
  referencedCouponId?: string;
  setReferencedCouponId: (referencedCouponId?: string) => void;
}

export default function BroadcastReferencesCouponSelection({ referencedCouponId, setReferencedCouponId }: Props) {
  const [showModal, setShowModal] = React.useState(false);
  const [coupons, setCoupons] = React.useState<Array<Partial<Coupon>>>([]);
  const cancel = () => {
    setReferencedCouponId(undefined);
    setShowModal(false);
  };

  React.useEffect(() => {
    ApiService
      .fetch('/shop_api/coupons')
      .then(response => response.json())
      .then(deserialize)
      .then(setCoupons);
  }, []);

  return (
    <div>
      <i onClick={() => setShowModal(!showModal)} className={`fal fa-ticket-alt field-toggle ${referencedCouponId ? 'active' : ''}`}/>
      {showModal && (
        <AppendToBody>
          <div className="modal confirm-dialog" tabIndex={-1} role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{t('broadcast.couponing.reference_coupon')}</h5>
                </div>
                <div className="modal-body">
                  <p>{t('broadcast.couponing.reference_coupon_description')}</p>
                  <select
                    value={referencedCouponId}
                    onChange={event => setReferencedCouponId(event.target.value)}
                    className="custom-select"
                  >
                    <option value="">{t('broadcast.couponing.select_coupon')}</option>
                    {coupons.map(coupon => <option key={coupon.id} value={coupon.id}>{coupon.title}</option>)}
                  </select>
                </div>
                <div className="modal-footer">
                  <button onClick={cancel} className="btn btn-secondary" type="button">
                    {t('broadcast.couponing.cancel')}
                  </button>
                  <button onClick={() => setShowModal(false)} className="btn btn-primary" type="button" disabled={!referencedCouponId}>
                    {t('broadcast.couponing.confirm')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </AppendToBody>
      )}
    </div>
  );
}
