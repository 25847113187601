import { Deserializer as JSONApiDeserializer, Serializer as JSONApiSerializer } from 'ts-jsonapi';

function couldBeDate(dateString: string) {
  return !!dateString.match(/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d\.\d\d\dZ$/);
}

function deserializeDatesInplace(obj: any) {
  if (!obj) {
    return;
  }
  Object.getOwnPropertyNames(obj).concat(Object.getOwnPropertySymbols(obj) as any).forEach(key => {
    if (typeof obj[key] === 'object') {
      deserializeDatesInplace(obj[key]);
    }
    if (typeof obj[key] === 'string' && couldBeDate(obj[key])) {
      obj[key] = new Date(obj[key]);
    }
  });
}

export function deserialize(data: any) {
  const deserialized = new JSONApiDeserializer({ keyForAttribute: 'camelCase' }).deserialize(data);
  deserializeDatesInplace(deserialized);
  return deserialized;
}

export function serialize(resourceName: string, attributes: string[], data: any) {
  const serialized = new JSONApiSerializer(resourceName, { id: 'id', keyForAttribute: 'camelCase', attributes }).serialize(data);
  if (serialized.data.id === 'undefined') {
    delete serialized.data.id;
  }
  return serialized;
}

export function serializeData(resourceName: string, data: any) {
  return serialize(resourceName, Object.keys(data), data);
}
