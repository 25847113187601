import 'bootstrap/js/dist/tooltip';
import React, { useLayoutEffect } from 'react';
import { DraftedBroadcastMessage } from 'src/javascripts/services/draftService';
import t from 'src/javascripts/services/translations';

interface Props {
  draftedMessage: DraftedBroadcastMessage;
  setVisibleOnWidget: (submitType: boolean) => any;
}

export default function BroadcastVisibleOnWidgetCheckbox({ draftedMessage, setVisibleOnWidget }: Props) {
  useLayoutEffect(() => {
    const tooltips = $('[data-toggle="tooltip"]') as { tooltip?: () => void };
    /* istanbul ignore next */
    if (tooltips.tooltip) {
      tooltips.tooltip();
    }
  });
  return (
    <div className="broadcast-plan">
      <div className="form-check">
        <input
          id="broadcast-for-widget-checkbox"
          className="form-check-input"
          type="checkbox"
          checked={draftedMessage.visibleOnWidget}
          onChange={() => setVisibleOnWidget(!draftedMessage.visibleOnWidget)}
        />
        <label className="form-check-label" htmlFor="broadcast-for-widget-checkbox">
          {t('broadcast.widget.send_to_widgets')}
        </label>
        <a href={`/pages/widget-guide/${window.CityMessenger.chatConfig.userChatId.substring('shop-'.length)}`}>
          <i
            id="broadcast-for-widget-tooltip"
            className="far fa-question-circle color-primary ml-1"
            data-toggle="tooltip"
            data-placement="top"
            title={t('broadcast.widget.tooltip')}
          />
        </a>
      </div>
    </div>
  );
}
