import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { BroadcastMultipartMessage, Channel, MultipartMessage, PusherUser, Room, Users } from './interfaces';

export class BaseChatService {
  protected sentDirectMessagesSubject: Subject<MultipartMessage>;
  protected removedDirectMessagesSubject: Subject<MultipartMessage>;
  protected sentBroadcastMessagesSubject: Subject<BroadcastMultipartMessage>;
  protected removedBroadcastMessagesSubject: Subject<BroadcastMultipartMessage>;
  protected unarchivedChannelsSubject: Subject<Channel>;
  protected roomsSubject: BehaviorSubject<Room[]>;
  protected connectedSubject: ReplaySubject<PusherUser>;
  protected usersSubject: ReplaySubject<Users>;

  constructor() {
    this.sentDirectMessagesSubject = new Subject<MultipartMessage>();
    this.removedDirectMessagesSubject = new Subject<MultipartMessage>();
    this.sentBroadcastMessagesSubject = new Subject<BroadcastMultipartMessage>();
    this.removedBroadcastMessagesSubject = new Subject<BroadcastMultipartMessage>();
    this.unarchivedChannelsSubject = new Subject<Channel>();
    this.roomsSubject = new BehaviorSubject<Room[]>([]);
    this.usersSubject = new ReplaySubject<Users>(1);
    this.connectedSubject = new ReplaySubject<PusherUser>(1);
  }

  get sentDirectMessages() {
    return this.sentDirectMessagesSubject.asObservable();
  }

  get removedDirectMessages() {
    return this.removedDirectMessagesSubject.asObservable();
  }

  get sentBroadcastMessages() {
    return this.sentBroadcastMessagesSubject.asObservable();
  }

  get removedBroadcastMessages() {
    return this.removedBroadcastMessagesSubject.asObservable();
  }

  get unarchivedChannels() {
    return this.unarchivedChannelsSubject.asObservable();
  }

  get rooms() {
    return this.roomsSubject.asObservable();
  }

  get onConnected() {
    return this.connectedSubject.asObservable();
  }

  get users() {
    return this.usersSubject.asObservable();
  }
}
