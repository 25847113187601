import { ChatMessagesContextValue } from 'src/javascripts/contexts/chatMessagesContext';
import { Room } from 'src/javascripts/shared/chat';

export const sortRoomsByLastActivity = (directChat: ChatMessagesContextValue['directChat'], rooms: Room[]) => {
  const byLastActivity = (room1: Room, room2: Room) => {
    const extractTime = (room: Room) => new Date((directChat(room.customer.id).teaser || room).createdAt).getTime();
    return extractTime(room2) - extractTime(room1);
  };

  return rooms.sort(byLastActivity);
};
