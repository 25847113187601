import moment from 'moment';
import { BroadCastSubmitType } from 'src/javascripts/components/messages/BroadcastChat';

export interface DraftedMessage {
  text: string;
  image: string | undefined;
  visibleOnWidget: boolean;
  referencedCouponId?: string;
}

export interface DraftedBroadcastMessage extends DraftedMessage {
  date: string;
  submitType: BroadCastSubmitType;
}

export enum DraftType {
  direct = 'direct',
  broadcast = 'broadcast',
}

const cache = {};

export default class DraftService<T extends DraftedMessage> {
  // tslint:disable
  static new(type: DraftType.direct): DraftService<DraftedMessage>;
  static new(type: DraftType.broadcast): DraftService<DraftedBroadcastMessage>;
  static new(type: DraftType): DraftService<DraftedMessage | DraftedBroadcastMessage> {
    return new DraftService(type);
  }
  // tslint:enable

  cache: {[key: string]: T} = cache;

  constructor(private type: DraftType) {}

  retrieve(id: string): T {
    const key = this.key(id);
    let message: T = this.cache[key];

    if (message) {
      return message;
    }

    message = JSON.parse(localStorage.getItem(key));

    if (message) {
      return message;
    }

    return this.emptyDraft;
  }

  save(id: string, message: T): void {
    const key = this.key(id);
    this.cache[key] = message;
    localStorage.setItem(key, JSON.stringify({ ...message }));
  }

  update(id: string, attributesToUpdate: Partial<T>): T {
    const message = { ...this.retrieve(id), ...attributesToUpdate };
    this.save(id, message);
    return message;
  }

  delete(id: string): void {
    const key = this.key(id);
    this.cache[key] = undefined;
    localStorage.removeItem(key);
  }

  get emptyDraft(): T {
    const message: any = {
      text: '',
      image: undefined,
      visibleOnWidget: true,
    };

    if (this.type === DraftType.broadcast) {
      message.date = moment().format('DD.MM.YYYY HH:mm');
      message.submitType = BroadCastSubmitType.send;
    }

    return message;
  }

  key(id: string): string {
    return `cm-draft-${this.type}-${id}`;
  }
}
