import moment, { Moment } from 'moment';
import React from 'react';
import * as Datetime from 'react-datetime';
import { BroadCastSubmitType } from 'src/javascripts/components/messages/BroadcastChat';
import { DraftedBroadcastMessage } from 'src/javascripts/services/draftService';
import t from 'src/javascripts/services/translations';

interface Props {
  draftedMessage: DraftedBroadcastMessage;
  setDraftSubmitType: (submitType: BroadCastSubmitType) => any;
  setDraftDate: (date: string) => any;
}

export default function BroadcastPlanInput({ draftedMessage: { submitType, date }, setDraftDate, setDraftSubmitType }: Props) {
  const yesterday = moment().subtract(1, 'day');
  const setDate = (newDate: Moment | string) => {
    if (typeof newDate !== 'string') {
      setDraftDate(newDate.format('DD.MM.YYYY HH:mm'));
    }
  };

  const toggleSubmitType = () => {
    let newSubmitType;
    if (submitType === BroadCastSubmitType.send) {
      newSubmitType = BroadCastSubmitType.plan;
      setDate(moment());
    } else {
      newSubmitType = BroadCastSubmitType.send;
    }
    setDraftSubmitType(newSubmitType);
  };

  const isValidDate = (current) => current.isAfter(yesterday);

  return (
    <div className="broadcast-plan">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={submitType === BroadCastSubmitType.plan}
          id="plan-broadcast"
          onChange={toggleSubmitType}
        />
        <label className="form-check-label" htmlFor="plan-broadcast">{t('broadcast.plan_option')}</label>
      </div>
      {submitType === BroadCastSubmitType.plan && (
        <div>
          <Datetime value={date} onChange={setDate} timeFormat="HH:mm" dateFormat="DD.MM.YYYY" isValidDate={isValidDate}/>
        </div>
      )}
    </div>
  );
}
