import React from 'react';
import { Subscription } from 'rxjs';
import { ChatMessagesContext } from 'src/javascripts/contexts/chatMessagesContext';
import { transformPlannedMessagesToConversations } from 'src/javascripts/services/messageHelpers';
import MessagingService from 'src/javascripts/services/messagingService';
import { sortRoomsByLastActivity } from 'src/javascripts/services/roomHelpers';
import { ToastContainer } from 'src/javascripts/services/toast';
import {
  BroadcastCategory,
  BroadcastMultipartMessage,
  IChatService,
  MultipartMessage,
  PlanInformation,
  PusherUser,
  Room,
  Users,
} from 'src/javascripts/shared/chat';
import MessengerRouter from './MessengerRouter';

interface State {
  currentUser: PusherUser;
  directRooms: Room[];
  users: Users;
  broadcastCategories: BroadcastCategory[];
  planInformation?: PlanInformation;
}

interface Props {
  chatService: IChatService;
}

export default class Messenger extends React.Component<Props, State> {
  static contextType = ChatMessagesContext;
  context!: React.ContextType<typeof ChatMessagesContext>;
  state: State = {
    currentUser: undefined,
    directRooms: [],
    users: {},
    broadcastCategories: [],
    planInformation: {
      sentBroadcastMessages: 0,
      sentDirectMessages: 0,
      totalBroadcastMessages: 0,
      totalDirectMessages: 0,
      costPerAdditionalBroadcast: 0,
      isVoucherIssuer: false,
    },
  };

  subscriptions: Subscription[] = [];

  componentDidMount() {
    this.initializeChat();
  }

  componentWillUnmount() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async initializeChat() {
    const { chatService } = this.props;
    if (!chatService) {
      return;
    }

    this.subscriptions.push(chatService.users.subscribe((users: Users) => this.setState({ users })));
    this.subscriptions.push(chatService.onConnected.subscribe((currentUser: PusherUser) => {
      this.setState({ currentUser: { ...currentUser, id: `shop-${currentUser.id}` } });
      this.subscriptions.push(chatService.sentDirectMessages.subscribe(this.receiveDirectMessage));
      this.subscriptions.push(chatService.removedDirectMessages.subscribe(this.removeDirectMessage));
      this.subscriptions.push(chatService.sentBroadcastMessages.subscribe(this.receiveBroadcastMessage));
      this.subscriptions.push(chatService.removedBroadcastMessages.subscribe(this.removeBroadcastMessage));

      MessagingService.fetchBroadcastCategories().then(broadcastCategories => this.setState({ broadcastCategories }));
      MessagingService.fetchHistory().then(history => {
        this.context.addDirectConversations(history.direct);
        this.context.addBroadcastConversations(history.categories);
        this.subscriptions.push(chatService.rooms.subscribe((directRooms: Room[]) => this.setDirectRooms(directRooms)));
      });
      this.loadPlannedMessages();
    }));
    this.loadPlanInformation();
  }

  loadPlannedMessages = () => {
    MessagingService.fetchPlannedBroadcastMessages().then(plannedBroadcastMessages => {
      this.context.addBroadcastConversations(transformPlannedMessagesToConversations(plannedBroadcastMessages));
    });
  }

  loadPlanInformation = () => {
    MessagingService.fetchPlanInformation().then((planInformation) => this.setState({ planInformation }));
  }

  receiveDirectMessage = (message: MultipartMessage) => {
    this.context.addDirectConversations({
      [message.room.customerId]: {
        messages: [message],
        unreadMessageIds: [message.id],
      },
    });
    this.setDirectRooms(this.state.directRooms);
  }

  removeDirectMessage = (message: MultipartMessage) => {
    this.context.removeDirectMessage(message.room.customerId, message.id);
  }

  receiveBroadcastMessage = (message: BroadcastMultipartMessage) => {
    this.context.addBroadcastConversations({
      [message.category.id]: {
        messages: [message],
        unreadMessageIds: [message.id],
      },
    });
  }

  removeBroadcastMessage = (message: BroadcastMultipartMessage) => {
    this.context.removeBroadcastMessage(message.category.id, message.id);
  }

  setDirectRooms = (directRooms: Room[]) => {
    this.setState({ directRooms: sortRoomsByLastActivity(this.context.directChat, directRooms) });
  }

  render() {
    const { currentUser, broadcastCategories, directRooms, users, planInformation } = this.state;

    return (
      <div className="messages-container">
        <MessengerRouter
          currentUser={currentUser}
          broadcastCategories={broadcastCategories}
          directRooms={directRooms}
          users={users}
          loadPlannedMessages={this.loadPlannedMessages}
          planInformation={planInformation}
          loadPlanInformation={this.loadPlanInformation}
        />
        <ToastContainer/>
      </div>
    );
  }
}
