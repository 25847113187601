import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { BroadcastCategory } from 'src/javascripts/shared/chat';
import { isWrapperApp } from 'src/javascripts/shared/chat/layout-helpers';

interface Props extends RouteComponentProps<{categoryId: string}> {
  categories: BroadcastCategory[];
}

export default function BroadcastChatHeader({ categories, history: { replace: redirect }, match: { params: { categoryId } } }: Props) {
  const category = categories.find(c => c.id === categoryId);
  if (!isWrapperApp() || !category) {
    return null;
  }
  return (
    <div className="chat-header">
      <a className="btn btn-link" onClick={() => redirect('/broadcasts')} title="back">
        <i className="fas fa-arrow-left"/>
      </a>
      <div className="avatar"><img src={category.icon}/></div>
      <div>{category.name}</div>
    </div>
  );
}
