import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useMessagesContext } from 'src/javascripts/contexts/chatMessagesContext';
import { BroadcastCategory, Users } from 'src/javascripts/shared/chat';
import BroadcastCategoryItem from './BroadcastCategoryItem';

interface Props extends RouteComponentProps {
  broadcastCategories: BroadcastCategory[];
  currentCategoryId?: string;
  users: Users;
}

export default function BroadcastCategories({ broadcastCategories, currentCategoryId, history, users }: Props) {
  const { broadcastChat } = useMessagesContext();

  return (
    <div className="rooms-container">
      <div className="list">
        {broadcastCategories.map(category => {
            return (
              <BroadcastCategoryItem
                key={category.id}
                category={category}
                currentCategoryId={currentCategoryId || ''}
                setCurrentCategoryId={id => history.push(`/broadcasts/${id}`)}
                teaser={broadcastChat(category.id).teaser}
                unreadMessages={broadcastChat(category.id).unreadMessageIds.length}
                users={users}
              />
            );
          },
        )}
      </div>
    </div>
  );
}
