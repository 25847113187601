import React, { ReactNode } from 'react';
import AppendToBody from 'src/javascripts/components/shared/AppendToBody';
import t from 'src/javascripts/services/translations';

interface Props {
  confirm: () => unknown;
  cancel: () => unknown;
  children: ReactNode;
  confirmText?: string;
  cancelText?: string;
}

export function ConfirmDialog({ confirm, cancel, children, confirmText, cancelText }: Props) {
  const getText = (text, scope) => text || t(`confirm_dialog.${scope}`);

  return (
    <AppendToBody>
      <div className="modal confirm-dialog" tabIndex={-1} role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">{children}</div>
            <div className="modal-footer">
              <button onClick={cancel} className="btn btn-secondary" type="button">{getText(cancelText, 'cancel')}</button>
              <button onClick={confirm} className="btn btn-primary" type="button">{getText(confirmText, 'confirm')}</button>
            </div>
          </div>
        </div>
      </div>
    </AppendToBody>
  );
}
