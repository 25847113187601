import objectToFormData from 'object-to-formdata';
import ApiService from 'src/javascripts/services/apiService';
import {
  BroadcastCategory,
  InitialHistory,
  MultipartMessage,
  MultipartMessagePayload,
  MultipartPlannedBroadcastChatMessagePerCategory, PlanInformation,
} from 'src/javascripts/shared/chat';
import { serialize } from '../shared/services/serialization';

export default class MessagingService {
  static submitMessage = async (customerId: string, text: string, image?: string) => {
    const parts: MultipartMessagePayload[] = [
      { type: 'text/plain', content: text },
    ];

    const body = objectToFormData(
      serialize('message', ['parts', 'customerId'], { parts, customerId }),
    );

    if (image) {
      body.append('data[attributes][remote_image_url]', image);
    }
    const data = await ApiService.fetch('/shop_api/messages', { method: 'post', body }, null).then(response => response.json());
    return data as MultipartMessage;
  }

  static submitBroadcastMessage = async (broadcastCategoryId: string, scheduledFor: string, text: string, image?: string,
                                         visibleOnWidget?: boolean, referencedCouponId?: string) => {
    const body = objectToFormData({
      broadcast_message: {
        text,
        broadcast_category_id: broadcastCategoryId,
        scheduled_for: scheduledFor,
        visible_on_widget: visibleOnWidget,
        referenced_coupon_id: referencedCouponId,
      },
    });
    if (image) {
      body.append('broadcast_message[remote_image_url]', image);
    }

    return ApiService.fetch('/shop_api/broadcast_messages', { method: 'post', body }, null);
  }

  static submitImage = async (image: File) => {
    const body = objectToFormData({
      message_image: {
        image,
      },
    });

    const response = await ApiService.fetch('/shop_api/message_images', { method: 'post', body }, null);
    return response.json();
  }

  static deleteBroadcastMessage = async (id: string) => {
    return ApiService.fetch(`/shop_api/broadcast_messages/${id}`, { method: 'delete' });
  }

  static deleteDirectMessage = async (id: string) => {
    return ApiService.fetch(`/shop_api/messages/${id}`, { method: 'delete' });
  }

  static fetchPlannedBroadcastMessages = async (): Promise<MultipartPlannedBroadcastChatMessagePerCategory> => {
    const response = await ApiService.fetch('/shop_api/broadcast_messages?planned=1');

    if (response.ok) {
      return response.json();
    } else {
      return [];
    }
  }

  static fetchBroadcastCategories = async (): Promise<BroadcastCategory[]> => {
    const response = await ApiService.fetch(`/shop_api/broadcast_categories?locale=${window.CityMessenger.chatConfig.locale}`);
    if (response.ok) {
      const categories = await response.json();
      return categories.data.map(({ id, attributes: { name, icon } }) => ({ id, name, icon }));
    } else {
      return [];
    }
  }

  static fetchHistory = async () => {
    const response = await ApiService.fetch('/shop_api/initial_history');
    return await response.json() as InitialHistory;
  }

  static fetchPlanInformation = async () => {
    const response = await ApiService.fetch('/shop_api/plan_information');
    const handleInfinity = (attributes: PlanInformation) => {
      for (const information in attributes) {
        if(attributes[information] === null) {
          attributes[information] = Infinity;
        }
      }
    };

    if (response.ok) {
      const planInformation = await response.json();
      handleInfinity(planInformation.data.attributes);
      return planInformation.data.attributes as PlanInformation;
    } else {
      return {} as PlanInformation;
    }
  }

  static setBroadcastCursor = async (position: string, categoryId: string) => {
    const body = JSON.stringify(serialize('messageCursor', ['position', 'categoryId'], { position, categoryId }));

    return ApiService.fetch('/shop_api/message_cursors/broadcast', { method: 'post', body });
  }

  static setDirectCursor = async (position: string, customerId: string) => {
    const body = JSON.stringify(serialize('messageCursor', ['position', 'customerId'], { position, customerId }));

    return ApiService.fetch('/shop_api/message_cursors/direct', { method: 'post', body });
  }

  static fetchDirectMessages = async ({ customerId, page }): Promise<MultipartMessage[]> => {
    const response = await ApiService.fetch(`/shop_api/direct_messages?customer_id=${customerId}&page=${page}`);

    if (response.ok) {
      return response.json();
    } else {
      return [];
    }
  }

  static fetchBroadcastMessages = async ({ categoryId, page }): Promise<MultipartMessage[]> => {
    const response = await ApiService.fetch(`/shop_api/broadcast_messages?broadcast_category_id=${categoryId}&page=${page}`);

    if (response.ok) {
      return response.json();
    } else {
      return [];
    }
  }
}
