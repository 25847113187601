import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ChatInput from 'src/javascripts/components/messages/ChatInput';
import Messages from 'src/javascripts/components/messages/Messages';
import { ChatMessagesContext } from 'src/javascripts/contexts/chatMessagesContext';
import DraftService, { DraftedMessage, DraftType } from 'src/javascripts/services/draftService';
import { getQuotaMessage, notReady } from 'src/javascripts/services/messageHelpers';
import MessagingService from 'src/javascripts/services/messagingService';
import { toast } from 'src/javascripts/services/toast';
import t from 'src/javascripts/services/translations';
import { PlanInformation, PusherUser, Room, Users } from 'src/javascripts/shared/chat';
import { isWrapperApp } from 'src/javascripts/shared/chat/layout-helpers';
import Rooms from './Rooms';

interface Props extends RouteComponentProps<{ roomId: string }> {
  currentUser: PusherUser;
  users: Users;
  rooms: Room[];
  reload: () => void;
  planInformation: PlanInformation;
}

interface State {
  currentRoom: Room;
  draftedMessage: DraftedMessage;
}

export default class DirectChat extends React.Component<Props, State> {
  static contextType = ChatMessagesContext;
  context!: React.ContextType<typeof ChatMessagesContext>;
  state: State = {
    currentRoom: null,
    draftedMessage: { text: '', image: undefined, visibleOnWidget: undefined },
  };
  draftService = DraftService.new(DraftType.direct);

  componentDidMount() {
    return this.initialize({} as Props, {} as State);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    this.initialize(prevProps, prevState);
  }

  initialize(prevProps: Props, prevState: State) {
    const { rooms, match: { params: { roomId } } } = this.props;
    const { currentRoom } = this.state;

    if (rooms.length && (!currentRoom || roomId !== prevProps.match.params.roomId)) {
      const newCurrentRoom = rooms.find(room => room.id === roomId) || rooms[0];
      this.setState({ currentRoom: newCurrentRoom });
    }

    if (currentRoom && currentRoom !== prevState.currentRoom) {
      const draftedMessage = this.draftService.retrieve(currentRoom.id);
      this.setState({ draftedMessage });
    }

    if (currentRoom) {
      this.updateMessageReadCursor();
    }
  }

  draftMessage = (text, image, cb?) => {
    this.setState({ draftedMessage: this.draftService.update(this.state.currentRoom.id, { text, image }) }, cb);
  }

  submitMessage = async () => {
    const { currentRoom, draftedMessage: { text, image } } = this.state;
    if (text || image) {
      this.draftService.delete(currentRoom.id);
      this.setState({ draftedMessage: this.draftService.emptyDraft });
      try {
        await MessagingService.submitMessage(currentRoom.customer.id, text, image);
        this.props.reload();
      } catch (_e) {
        this.draftMessage(text, image);
        toast('error', 'chat.message.submission_failed');
      }
    }
  }

  deleteMessage = async message => {
    try {
      const response = await MessagingService.deleteDirectMessage(message.id);
      if (response.ok) {
        this.context.removeDirectMessage(this.state.currentRoom.customer.id, message.id);
      }
    } catch (_e) {
      toast('error', 'chat.message.deletion_failed');
    }
  }

  loadEarlierMessages = async (page: number) => {
    const { id: customerId } = this.state.currentRoom.customer;
    const messages = await MessagingService.fetchDirectMessages({ customerId, page });
    this.context.addDirectConversations({ [customerId]: { messages, unreadMessageIds: [] } });
    return messages;
  }

  render() {
    const { currentUser, rooms, users, planInformation } = this.props;
    const { currentRoom, draftedMessage } = this.state;

    if (!currentUser || !planInformation) {
      return notReady(t('direct.not_ready.connecting'));
    }

    return (
      <div className="direct panel with-chat">
        {!isWrapperApp() && (
          <Rooms
            {...this.props}
            rooms={rooms}
            currentRoom={currentRoom}
            currentUser={currentUser}
            users={users}
          />
        )}
        {(!rooms.length && !isWrapperApp()) && <div className="no-rooms position-relative">{t('direct.not_ready.no_messages')}</div>}
        {currentRoom && (
          <>
            <Messages
              messages={this.context.directChat(this.currentRoomRecipient).messages}
              loadEarlierMessages={this.loadEarlierMessages}
              recipient={this.currentRoomRecipient}
              currentUserId={currentUser.id}
              quotaMessage={getQuotaMessage(planInformation.sentDirectMessages, planInformation.totalDirectMessages, 'dm')}
              deleteAction={this.deleteMessage}
              users={users}
            />
            <ChatInput
              draftedMessage={draftedMessage}
              draftMessage={this.draftMessage}
              isBlocked={currentRoom.blocked || false}
              submitMessage={this.submitMessage}
              isQuotaReached={planInformation.sentDirectMessages >= planInformation.totalDirectMessages}
            />
          </>
        )}
      </div>
    );
  }

  private get currentRoomRecipient(): string {
    return this.state.currentRoom.customer.id;
  }

  private updateMessageReadCursor() {
    const { unreadMessageIds, teaser } = this.context.directChat(this.currentRoomRecipient);
    if (unreadMessageIds.length) {
      this.context.markDirectChatAsRead(this.currentRoomRecipient);
      MessagingService.setDirectCursor(teaser.id, this.currentRoomRecipient);
    }
  }
}
