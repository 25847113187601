import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { isWrapperApp } from 'src/javascripts/shared/chat/layout-helpers';

interface Props extends RouteComponentProps {
  toggles: Array<[string, string]>;
}

export default function MessageToggles({ toggles, location: { pathname } }: Props) {
  return (
    <div className={`panel-toggles btn-group btn-group-toggle ${isWrapperApp() ? 'w-100' : ''}`}>
      {toggles.map(([path, text], index) => (
          <Link
            to={path}
            key={index}
            className={`panel-toggle btn ${pathname.includes(path) ? 'btn-primary' : 'btn-secondary'} ${isWrapperApp() ? 'w-50 py-3' : ''}`}
          >
            {text}
          </Link>
      ))}
    </div>
  );
}
