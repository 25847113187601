import React from 'react';
import AppendToBody from 'src/javascripts/components/shared/AppendToBody';
import t from 'src/javascripts/services/translations';
// @ts-ignore
import smsTemplate from '../../../../images/sms-template.png';
import { isWrapperApp } from '../../shared/chat/layout-helpers';

interface Props {
  cancel: () => void;
}

export default function InvitationModal({ cancel }: Props) {
  const [file, setFile] = React.useState<File | null>(null);
  const [confirmed, setConfirmed] = React.useState(false);
  return (
    <AppendToBody>
      <div className="modal confirm-dialog" tabIndex={-1} role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Telefonnummer Import</h5>
            </div>
            <div className="invitation-modal">
              {confirmed ? (
                <div className="modal-body">
                  <img className="w-100 border" src={smsTemplate}/>
                  <p className="mt-4" dangerouslySetInnerHTML={{ __html: t('customer_invitation.import.notice') }}/>
                </div>
              ) : (
                <div className="modal-body">
                  <p dangerouslySetInnerHTML={{ __html: t('customer_invitation.import.description') }}/>
                </div>
              )}
              <form action="/relationships/invite" method="post" encType="multipart/form-data" className="modal-footer">
                <input type="hidden" name="authenticity_token" value={window.CityMessenger.csrfToken}/>
                {isWrapperApp() && <input type="hidden" name="wrapper_app" value="1" id="wrapper_app"/>}
                <div className="input-group mb-3">
                  <div className="custom-file">
                    <input
                      id="numbersFile"
                      type="file"
                      name="numbers_file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      className="custom-file-input"
                      required={true}
                      onInput={event => setFile(event.currentTarget.files[0])}
                    />
                    <label className="custom-file-label" htmlFor="inputGroupFile01">
                      {file ? file.name : t('customer_invitation.import.label')}
                    </label>
                  </div>
                </div>
                <button onClick={cancel} className="btn btn-secondary" type="button">
                  {t('customer_invitation.import.cancel')}
                </button>
                {confirmed ? (
                  <input type="submit" value={t('customer_invitation.import.confirm')} className="btn btn-primary"/>
                ) : (
                  <button className="btn btn-primary" onClick={() => setConfirmed(true)}>
                    {t('customer_invitation.import.submit')}
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </AppendToBody>
  );
}
