import React from 'react';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import BroadcastChat from 'src/javascripts/components/messages/BroadcastChat';
import DirectChat from 'src/javascripts/components/messages/DirectChat';
import MessageToggles from 'src/javascripts/components/messages/MessageToggles';
import t from 'src/javascripts/services/translations';
import { BroadcastCategory, PlanInformation, PusherUser, Room, Users } from 'src/javascripts/shared/chat';
import { isWrapperApp } from 'src/javascripts/shared/chat/layout-helpers';
import BroadcastCategories from './BroadcastCategories';
import BroadcastChatHeader from './BroadcastChatHeader';
import CustomerInvitation from './CustomerInvitation';
import DirectChatHeader from './DirectChatHeader';
import Rooms from './Rooms';

interface Props {
  currentUser: PusherUser;
  directRooms: Room[];
  users: Users;
  broadcastCategories: BroadcastCategory[];
  loadPlannedMessages: () => void;
  planInformation: PlanInformation;
  loadPlanInformation: () => void;
}

export default class MessengerRouter extends React.Component<Props> {
  renderDirectChat = props => (
    <div>
      <DirectChatHeader {...props} users={this.props.users} rooms={this.props.directRooms} />
      <DirectChat
        {...props}
        currentUser={this.props.currentUser}
        users={this.props.users}
        rooms={this.props.directRooms}
        planInformation={this.props.planInformation}
        reload={this.props.loadPlanInformation}
      />
    </div>
  )

  renderBroadcastChat = props => (
    <div>
      <BroadcastChatHeader {...props} categories={this.props.broadcastCategories} />
      <BroadcastChat
        {...props}
        currentUser={this.props.currentUser}
        broadcastCategories={this.props.broadcastCategories}
        planInformation={this.props.planInformation}
        users={this.props.users}
        reload={() => {
          this.props.loadPlannedMessages();
          this.props.loadPlanInformation();
        }}
      />
    </div>
  )

  renderDirect = props => (
    isWrapperApp() ? (
      <Rooms
        {...props}
        rooms={this.props.directRooms}
        currentRoom={null}
        currentUser={this.props.currentUser}
        users={this.props.users}
      />
    ) : this.renderDirectChat(props)
  )

  renderBroadcasts = props => (
    isWrapperApp() ? (
      <BroadcastCategories
        {...props}
        broadcastCategories={this.props.broadcastCategories}
        users={this.props.users}
      />
    ) : this.renderBroadcastChat(props)
  )

  render() {
    return (
      <>
        <Router>
          {/* MessageToggles are wrapped in Route, so that it can re-render the className based on the current path
            Exactly match only in a wrapper app, so that the toggles are hidden in the chat view. */ }
          <Route
            exact={isWrapperApp()}
            path={['/direct', '/broadcasts']}
            render={props => (
              <MessageToggles {...props} toggles={[['/direct', t('direct.title')], ['/broadcasts', t('broadcast.title')]]} />
            )}
          />
          <Switch>
            <Redirect exact={true} from="/" to="/direct" />
            <Route
              exact={true}
              path={['/direct']}
              render={this.renderDirect}
            />
            <Route
              path="/direct/:roomId"
              render={this.renderDirectChat}
            />
            <Route
              exact={true}
              path={['/broadcasts']}
              render={this.renderBroadcasts}
            />
            <Route
              path="/broadcasts/:categoryId"
              render={this.renderBroadcastChat}
            />
            <Route
              path="/customer_invitation"
              render={props => <CustomerInvitation {...props} showHeader={true} />}
            />
          </Switch>
        </Router>
      </>
    );
  }
}
