import React from 'react';
import { toast as _toast, ToastContainer as _ToastContainer, TypeOptions } from 'react-toastify';
import t from 'src/javascripts/services/translations';

export const toast = (type: TypeOptions, translationKey: string) => {
  const icons = {
    error: 'exclamation-circle',
    warning: 'exclamation-triangle',
    success: 'check-circle',
    info: 'info-circle',
    default: 'info-circle',
  };

  _toast(
    (
      <>
        <i className={`fas fa-${icons[type]}`}/>
        <div>{t(`${translationKey}.title`)}</div>
        <div>{t(`${translationKey}.description`)}</div>
      </>
    ),
    { type },
  );
};

export const CloseButton = ({ closeToast }) => <i className="fas fa-times" onClick={closeToast}/>;

export const ToastContainer = () => (
  <_ToastContainer
    position="bottom-left"
    autoClose={false}
    newestOnTop={true}
    closeButton={<CloseButton closeToast={true}/>}
  />
);
