import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

interface Props {
  children: ReactNode;
}

export default class AppendToBody extends React.Component<Props> {
  private readonly element: HTMLDivElement;

  constructor(props) {
    super(props);
    this.element = document.createElement('div');
  }

  componentDidMount() {
    this.body.appendChild(this.element);
  }

  componentWillUnmount() {
    this.body.removeChild(this.element);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.element,
    );
  }

  get body() {
    return document.getElementsByTagName('body')[0];
  }
}
