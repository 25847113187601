import moment from 'moment';
import React from 'react';
import t from 'src/javascripts/services/translations';
import {
  ChatMessage,
  ChatUser,
  Conversations,
  InlineMessagePart,
  MultipartMessage,
  MultipartPlannedBroadcastChatMessage,
  MultipartPlannedBroadcastChatMessagePerCategory,
  PlannedBroadcastChatMessage,
  UrlMessagePart,
} from 'src/javascripts/shared/chat';

type AnyMessage = ChatMessage | PlannedBroadcastChatMessage;

export interface TransformableMessage extends Partial<MultipartMessage>, Partial<MultipartPlannedBroadcastChatMessage> {
}

export function transformToChatMessage<T>(multipartMessage: TransformableMessage): T {
  const textParts = multipartMessage.parts.filter(part =>
    part.partType === 'inline' && part.payload.type === 'text/plain');

  const textPart = textParts[0] as InlineMessagePart;
  const quotedTextPart = (textParts.length > 1) ? textParts[1] as InlineMessagePart : undefined;

  const imagePart = multipartMessage.parts.find(part => part.partType === 'url') as UrlMessagePart;

  const text = textPart ? textPart.payload.content : '';
  const quote = quotedTextPart ? quotedTextPart.payload.content : '';
  const image = imagePart ? imagePart.payload.url : undefined;

  const { id, createdAt, sender, room, planned, plannedId, referencedCouponId } = multipartMessage;
  return { id, createdAt, sender, room, text, quote, image, planned, plannedId, referencedCouponId } as any;
}

export function transformToChatMessages<T>(multipartMessages: TransformableMessage[]) {
  return multipartMessages.map((multipartMessage) => transformToChatMessage<T>(multipartMessage));
}

export function transformPlannedMessagesToConversations(plannedMessages: MultipartPlannedBroadcastChatMessagePerCategory): Conversations {
  return Object.entries(plannedMessages).reduce(((conversations, [key, messages]) => {
    conversations[key] = { messages, unreadMessageIds: [] };
    return conversations;
  }), {});
}

export function formatDate(time: string | Date) {
  moment.locale('de-ch');
  return moment(time).format('Do MMM HH:mm');
}

export function defaultAvatarForUser(user: ChatUser) {
  return (user && user.avatar && user.avatar.thumb.url) || window.CityMessenger.avatarFallbackImage;
}

export function notReady(message: string) {
  return (
    <div className="panel">
      <div className="not-ready">
        <div>{message}</div>
      </div>
    </div>
  );
}

export const getQuotaMessage = (sentMessages: number, totalMessages: number, scope: 'bc' | 'dm') => {
  if (scope === 'bc' && totalMessages === 0) {
    return null;
  }

  if (totalMessages !== Infinity) {
    return `${sentMessages} / ${totalMessages} ${t(`chat.quota.${scope}`)}`;
  } else {
    return '';
  }
};

export const selectTeaser = (messages: ChatMessage[]) => {
  for (let index = messages.length - 1; index >= 0; index--) {
    if (!isPlanned(messages[index]) || isSending(messages[index])) {
      return messages[index];
    }
  }
};

export const isPlanned = (message: AnyMessage) => {
  return (message as PlannedBroadcastChatMessage).planned;
};

export const isSending = (message: AnyMessage) => {
  return isPlanned(message) && Date.parse((message as any).createdAt) < Date.now();
};

export const uniqMessageComparator = (message: AnyMessage, otherMessage: AnyMessage): boolean => {
  return message.id === otherMessage.id || (
    (message as PlannedBroadcastChatMessage).plannedId && (message as PlannedBroadcastChatMessage).plannedId === otherMessage.id
  ) || (
    (otherMessage as PlannedBroadcastChatMessage).plannedId && (otherMessage as PlannedBroadcastChatMessage).plannedId === message.id
  );
};
