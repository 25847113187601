import moment from 'moment';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import BroadcastPlanInput from 'src/javascripts/components/messages/BroadcastPlanInput';
import BroadcastVisibleOnWidgetCheckbox from 'src/javascripts/components/messages/BroadcastVisibleOnWidgetCheckbox';
import ChatInput from 'src/javascripts/components/messages/ChatInput';
import Messages from 'src/javascripts/components/messages/Messages';
import { ConfirmDialog } from 'src/javascripts/components/shared/ConfirmDialog';
import { ChatMessagesContext } from 'src/javascripts/contexts/chatMessagesContext';
import DraftService, { DraftedBroadcastMessage, DraftType } from 'src/javascripts/services/draftService';
import { getQuotaMessage, notReady } from 'src/javascripts/services/messageHelpers';
import MessagingService from 'src/javascripts/services/messagingService';
import { toast } from 'src/javascripts/services/toast';
import t from 'src/javascripts/services/translations';
import {
  BroadcastCategory,
  ChatMessage,
  PlanInformation,
  PusherUser,
  Users,
} from 'src/javascripts/shared/chat';
import { isWrapperApp } from 'src/javascripts/shared/chat/layout-helpers';
import BroadcastCategories from './BroadcastCategories';

interface Props extends RouteComponentProps<{ categoryId: string }> {
  currentUser: PusherUser;
  broadcastCategories: BroadcastCategory[];
  reload: () => void;
  planInformation: PlanInformation;
  users: Users;
}

export enum BroadCastSubmitType {
  send = 'send',
  plan = 'plan',
}

interface State {
  currentCategoryId: string;
  draftedMessage: DraftedBroadcastMessage;
  resolveConfirmSubmitMessage?: (confirmed: boolean) => void;
  showStarter: boolean;
}

export default class BroadcastChat extends React.Component<Props, State> {
  static contextType = ChatMessagesContext;

  context!: React.ContextType<typeof ChatMessagesContext>;
  state: State = {
    currentCategoryId: '',
    draftedMessage: undefined,
    showStarter: true,
  };
  draftService = DraftService.new(DraftType.broadcast);

  componentDidMount() {
    this.initialize({} as Props, {} as State);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    this.initialize(prevProps, prevState);
  }

  initialize(prevProps: Props, prevState: State) {
    const { currentCategoryId } = this.state;
    const { broadcastCategories, match: { params: { categoryId } } } = this.props;

    if (broadcastCategories.length && (broadcastCategories !== prevProps.broadcastCategories
      || currentCategoryId !== categoryId && broadcastCategories.find(c => c.id === categoryId))) {
      this.setState({ currentCategoryId: categoryId || broadcastCategories[0].id });
    }

    if (currentCategoryId !== prevState.currentCategoryId) {
      const draftedMessage = this.draftService.retrieve(this.state.currentCategoryId);
      this.setState({ draftedMessage });
    }

    if (currentCategoryId) {
      this.updateMessageReadCursor();
    }
  }

  setDraftSubmitType = (submitType: BroadCastSubmitType) => {
    this.setState({ draftedMessage: this.draftService.update(this.state.currentCategoryId, { submitType }) });
  }

  setDraftDate = (date: string) => {
    this.setState({ draftedMessage: this.draftService.update(this.state.currentCategoryId, { date }) });
  }

  setDraftMessage = (text, image, cb?) => {
    this.setState({ draftedMessage: this.draftService.update(this.state.currentCategoryId, { text, image }) }, cb);
  }

  setVisibleOnWidget = (visibleOnWidget: boolean) => {
    this.setState({ draftedMessage: this.draftService.update(this.state.currentCategoryId, { visibleOnWidget }) });
  }

  setReferencedCouponId = (referencedCouponId: string) => {
    this.setState({ draftedMessage: this.draftService.update(this.state.currentCategoryId, { referencedCouponId }) });
  }

  submitMessage = async () => {
    const { currentCategoryId, draftedMessage: { text, image, date, submitType, visibleOnWidget, referencedCouponId } } = this.state;
    if (text || image) {
      if (!await this.confirmSubmitMessage()) { return; }
      const scheduledFor = submitType === BroadCastSubmitType.plan ? date : moment().format('DD.MM.YYYY HH:mm:ss');
      this.draftService.delete(this.state.currentCategoryId);
      this.setState({ draftedMessage: this.draftService.emptyDraft });
      try {
        await MessagingService.submitBroadcastMessage(currentCategoryId, scheduledFor, text, image, visibleOnWidget, referencedCouponId);
        this.props.reload();
      } catch (error) {
        this.setDraftMessage(text, image);
        const errorJson = await error.json().catch(() => console.error('Broadcast submit failed'));
        if (errorJson?.errors?.scheduled_for) {
          toast('error', 'chat.message.scheduling_failed');
        } else {
          toast('error', 'chat.message.submission_failed');
        }
      }
    }
  }

  confirmSubmitMessage = async () => {
    const confirmed: boolean = await new Promise(resolve =>
      this.setState({
        resolveConfirmSubmitMessage: confirmed => resolve(confirmed),
      }),
    );
    this.setState({ resolveConfirmSubmitMessage: undefined });
    return confirmed;
  }

  editMessage = async message => {
    const { text, createdAt, visibleOnWidget, referencedCouponId } = message;
    const date = moment(createdAt).format('DD.MM.YYYY HH:mm');
    const draftedMessage = { text, image: undefined, date, submitType: BroadCastSubmitType.plan, visibleOnWidget, referencedCouponId };
    const oldDraftedMessage = this.state.draftedMessage;
    this.draftService.save(this.state.currentCategoryId, draftedMessage);
    this.setState({ draftedMessage });
    await this.deleteMessage(message, () => {
      toast('error', 'chat.message.editing_failed');
      this.draftService.save(this.state.currentCategoryId, oldDraftedMessage);
      this.setState({ draftedMessage: oldDraftedMessage });
    });
  }

  deleteMessage = async (message: ChatMessage, errorHandler?: () => void) => {
    try {
      const response = await MessagingService.deleteBroadcastMessage(message.id);
      if (response.ok) {
        this.context.removeBroadcastMessage(this.state.currentCategoryId, message.id);
      }
    } catch (_e) {
      if(errorHandler) {
        errorHandler();
      } else {
        toast('error', 'chat.message.deletion_failed');
      }
    }
  }

  loadEarlierMessages = async (page: number) => {
    const { currentCategoryId } = this.state;
    const messages = await MessagingService.fetchBroadcastMessages({ categoryId: currentCategoryId, page });
    this.context.addBroadcastConversations({ [currentCategoryId]: { messages, unreadMessageIds: [] } });
    return messages;
  }

  render() {
    const { broadcastCategories, currentUser, planInformation, users } = this.props;
    const { currentCategoryId, draftedMessage, resolveConfirmSubmitMessage } = this.state;

    if (!currentUser || !draftedMessage || !planInformation) {
      return notReady(t('broadcast.not_ready.connecting'));
    }

    const isQuotaReached = planInformation.sentBroadcastMessages >= planInformation.totalBroadcastMessages;
    const isMessageInThisMonth = moment(draftedMessage.date, 'DD.MM.YYYY hh:mm').isSame(moment(), 'month');

    return (
      <div className="panel with-chat">
        {!isWrapperApp() && (
          <BroadcastCategories
            {...this.props}
            broadcastCategories={broadcastCategories}
            currentCategoryId={currentCategoryId}
          />
        )}
        <Messages
          messages={this.context.broadcastChat(currentCategoryId).messages}
          currentUserId={currentUser.id}
          recipient={currentCategoryId}
          loadEarlierMessages={this.loadEarlierMessages}
          actions={[['fa-edit', this.editMessage]]}
          quotaMessage={getQuotaMessage(planInformation.sentBroadcastMessages, planInformation.totalBroadcastMessages, 'bc')}
          deleteAction={this.deleteMessage}
          users={users}
        />
        <ChatInput
          isBlocked={false}
          draftedMessage={draftedMessage}
          submitMessage={this.submitMessage}
          draftMessage={this.setDraftMessage}
          submitType={draftedMessage.submitType}
          isQuotaReached={false}
          isVoucherIssuer={planInformation.isVoucherIssuer}
          setReferencedCouponId={this.setReferencedCouponId}
        >
          <div className="d-flex justify-content-start">
            <BroadcastPlanInput
              draftedMessage={draftedMessage}
              setDraftSubmitType={this.setDraftSubmitType}
              setDraftDate={this.setDraftDate}
            />
            <BroadcastVisibleOnWidgetCheckbox
              draftedMessage={draftedMessage}
              setVisibleOnWidget={this.setVisibleOnWidget}
            />
          </div>
        </ChatInput>
        {resolveConfirmSubmitMessage && (
          <ConfirmDialog
            confirm={() => resolveConfirmSubmitMessage(true)}
            cancel={() => resolveConfirmSubmitMessage(false)}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: t('broadcast.confirm_dialog', { time: draftedMessage.date, category: this.currentCategoryName }),
              }}
            />
            {isQuotaReached && isMessageInThisMonth && (
              <div className="alert alert-danger m-0 mt-4">
                {t('chat_input.quota_reached', { cost: planInformation.costPerAdditionalBroadcast })}
              </div>
            )}
          </ConfirmDialog>
        )}
      </div>
    );
  }

  private get currentCategoryName(): string {
    return this.props.broadcastCategories.find(({ id }) => id === this.state.currentCategoryId).name;
  }

  private updateMessageReadCursor() {
    const { currentCategoryId } = this.state;
    const { unreadMessageIds, teaser } = this.context.broadcastChat(currentCategoryId);
    if (unreadMessageIds.length) {
      this.context.markBroadcastChatAsRead(currentCategoryId);
      MessagingService.setBroadcastCursor(teaser.id, currentCategoryId);
    }
  }
}
