import React from 'react';
import Linkify from 'react-linkify';
import ImageWithLightbox from 'src/javascripts/components/messages/ImageWithLightbox';
import { defaultAvatarForUser, formatDate, isPlanned, isSending } from 'src/javascripts/services/messageHelpers';
import t from 'src/javascripts/services/translations';
import { BroadcastMultipartMessage, ChatMessage, PlannedBroadcastChatMessage, Users } from 'src/javascripts/shared/chat';

enum MessageType {
  Planned = 'planned',
  Sent = 'sent',
  Sending = 'sending',
  Received = 'received',
}

interface Props {
  currentUserId: string;
  message: ChatMessage | PlannedBroadcastChatMessage;
  imgLoaded: () => void;
  actions: Array<[string, (message: ChatMessage | PlannedBroadcastChatMessage) => any]>;
  deleteAction: (message: ChatMessage) => void;
  users: Users;
}

export default function Message({ currentUserId, message, actions, imgLoaded, deleteAction, users }: Props) {
  let sentOrReceived = currentUserId === message.sender.chatUserId ? MessageType.Sent : MessageType.Received;
  const sender = users[message.sender.chatUserId];

  if (isPlanned(message)) {
    sentOrReceived = MessageType.Planned;
  }

  if (isSending(message)) {
    sentOrReceived = MessageType.Sending;
  }

  const confirmDeletion = () => {
    if (window.confirm(t('chat.message.deletion_confirmation')) === true) {
      deleteAction(message as ChatMessage);
    }
  };

  return (
    <div className={`${sentOrReceived} message`}>
      <div className="avatar"><img src={defaultAvatarForUser(sender)}/></div>
      <div className="name">{sender.name}</div>
      <div className="content">
        {message.image && <ImageWithLightbox src={message.image} onLoad={imgLoaded}/>}
        <div className="actions">
          {sentOrReceived === MessageType.Planned &&
          actions.map(([icon, cb], index) =>
            <i key={index} onClick={() => cb(message)} className={`far ${icon}`}/>)
          }
          {(sentOrReceived === MessageType.Sent || sentOrReceived === MessageType.Planned) &&
          <i onClick={confirmDeletion} className={'far fa-trash-alt'}/>
          }
        </div>
        {(message as ChatMessage).quote && <div className="quote">{(message as ChatMessage).quote}</div>}
        <div className="text">
          <Linkify properties={{ target: '_blank', rel: 'noopener nofollow' }}>{message.text}</Linkify>
        </div>
        <div className="time">
          {(message as any).referencedCouponId && <i className="fal fa-ticket-alt mr-2"/>}
          {formatDate(message.createdAt)}
        </div>
      </div>
    </div>
  );
}
