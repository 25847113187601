import { ChatMessageSender, ChatUser, MessageSender, Users } from './interfaces';

export const convertChatId = (id: string) => id.replace('customer-', '').replace('shop-', '');

export const getChatMessageSender = (sender: MessageSender, users: Users) => {
  const user = users && users[sender.chatUserId];
  if (user) {
    return transformChatMessageSender(user);
  }
  return sender;
};

export const transformChatMessageSender = (user?: ChatUser): ChatMessageSender | undefined => {
  if (user) {
    return { ...user, id: user.chatUserId, avatarUrl: user.avatar && user.avatar.thumb.url || null };
  }
};
